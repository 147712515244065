/* eslint-disable check-file/filename-blocklist */
import type { ComponentProps, RefObject } from 'react';

import type { StylesConfig } from '@atlaskit/select';

import type { FlagsStateContainer } from '@confluence/flags';
import type { ShareResponse } from '@confluence/share';

import type { RestrictionsDialogQuery_content_nodes_ancestors } from './RestrictionsDialog/__types__/RestrictionsDialogQuery';
import type { RestrictionsRESTObj } from './RestrictionsREST/restrictionsRESTUtils';
import type {
	groupEntityShape,
	userEntityShape,
	EntityShape,
} from './RestrictionsDialog/restrictions-data-transform-helpers';
import type { UserAndGroupDataContainer } from './UserAndGroupSelectForm';

type UserAndGroupSelectFormComponentMode = 'RESTRICT_EDIT' | 'RESTRICT_VIEW';
type GenericFunction = (...args: any[]) => any;
type GenericObject = { [key: string]: any };

export enum ContentRestrictionType {
	NO_RESTRICTIONS = 'NO_RESTRICTIONS',
	RESTRICT_EDIT = 'RESTRICT_EDIT',
	RESTRICT_VIEW = 'RESTRICT_VIEW',
}

export enum RestrictionFrom {
	DIRECT = 'DIRECT',
	MULTIPLE = 'MULTIPLE',
	NONE = 'NONE',
	PAGE_INHERITED = 'PAGE_INHERITED',
	SPACE = 'SPACE',
}

export enum EntityType {
	GROUP = 'GROUP',
	USER = 'USER',
}

export enum PermissionType {
	EDIT = 'EDIT',
	VIEW = 'VIEW',
}

export enum ErrorMessageType {
	NO_ANCESTOR_VIEW = 'NO_ANCESTOR_VIEW',
	NO_SPACE_VIEW = 'NO_SPACE_VIEW',
	NO_SPACE_EDIT = 'NO_SPACE_EDIT',
}

export interface UserAndGroupSelectFormComponentProps {
	currentPrincipalsCount: PrincipalsCountType;
	mode: UserAndGroupSelectFormComponentMode;
	onAdd: GenericFunction;
	dataContainer: UserAndGroupDataContainer;
	menuPortalTarget?: HTMLElement | null;
	styles?: StylesConfig;
}

export interface RestrictionsDialogTriggerProps {
	contentId?: string;
	triggeredFrom?: string;
	markItemAsLoaded?: GenericFunction;
	isDisabled?: boolean;
	contentSubType?: string | null;
}

export interface RestrictionsDataContainerConfig {
	loadUuid?: string;
	contentId?: string | null;
	userCanEditRestrictions?: boolean;
	userCanEditContent?: boolean;
	currentUserInfo?: any;
	hasInheritedRestrictions?: boolean;
	hasRestrictions?: boolean;
	isLoaded?: boolean;
	restrictionsDetails?: any;
	spaceKey?: string | null;
	spaceContainsExternalCollaborators?: boolean;
	externalCollaboratorCount?: number;
	operations?: any;
	contentTitle?: string | null;
	edition?: any;
	isSiteAdmin?: boolean | null;
	contentType?: string | null;
	contentSubType?: string | null;
	isExternalShareVariation?: boolean;
	isPageExternallyShared?: boolean;
	isExternalShareEnabledForSite?: boolean;
	isContentExternallySharable?: boolean;
	canEditPublicLink?: boolean;
	ancestors?: RestrictionsDialogQuery_content_nodes_ancestors | null[];
}

export interface InheritedRestrictionsProps {
	contentId: string;
	newParentContentId?: string;
}

export interface InheritedRestrictionsComponentProps {
	restrictedViewPages: {
		contentId?: string;
		title?: string;
		spaceKey?: string;
		userList: { accountId: string; displayName: string }[];
		groupList: { name: string }[];
		href?: string;
	}[];
}

export interface InheritedViewRestrictionsListProps {
	restrictedViewPages: any[];
}

export interface RestrictEditProps {
	currentUserInfo?: GenericObject;
	userCanEditRestrictions?: boolean;
	usersAndGroups?: any[];
	onRemoveItem?: GenericFunction;
	contentId: string;
	onRefresh(): void;
	scrollToIndex: number | null;
	onScrolled: GenericFunction;
	hasInheritedRestrictions: boolean;
	handleAccessDetailsOpen: () => void;
}

export interface RestrictViewProps {
	currentUserInfo?: GenericObject;
	userCanEditRestrictions?: boolean;
	usersAndGroups?: any[];
	onRemoveItem?: GenericFunction;
	onChangeItem?: GenericFunction;
	contentId: string;
	onRefresh(): void;
	scrollToIndex: number | null;
	onScrolled: GenericFunction;
	hasInheritedRestrictions: boolean;
	handleAccessDetailsOpen: () => void;
}

export type RestrictionsDialogProps = {
	contentId?: string;
	hideSpinner?: () => void;
	onClose: (callback?: () => void) => void;
	submitOverride?: (restrictions: RestrictionsRESTObj) => void;
	preloadRestrictions?: RestrictionsRESTObj;
	preselectRestrictionType?: ContentRestrictionType;
};

export type RestrictionsDialogInnerComponentProps = {
	restrictionsLoadUuid: string;
	contentId: string;
	createAnalyticsEvent: GenericFunction;
	flags?: FlagsStateContainer;
	onClose: (callback?: () => void) => void;
	restrictionsDataContainer: GenericObject;
	usersAndGroupContainer: UserAndGroupDataContainer;
	hideSpinner?: () => void;
	spaceKey: string;
	submitOverride?: (restrictions: RestrictionsRESTObj) => void;
	preloadRestrictions?: RestrictionsRESTObj;
	preselectRestrictionType?: ContentRestrictionType;
	isLivePage: boolean;
	shareToNewRestrictionsEntities: (newEntities: Required<EntityShape>[]) => Promise<ShareResponse>;
};

export type RestrictionsDialogInnerComponentState = {
	allowedOperations?: any;
	applyingUpdates: boolean;
	contentTitle?: any;
	contentType?: string;
	contentSubType?: string | null;
	contentStatus: string | null | undefined;
	currentUserInfo?: any;
	edition?: any;
	hasInheritedRestrictions?: any;
	hasRestrictions?: any;
	hasViewRestrictions: boolean;
	currentOpenDialog: OpenDialogType;
	isLoaded: boolean;
	originalRestrictionType?: any;
	selectedRestrictionType?: any;
	userCanEditRestrictions?: any;
	usersAndGroups: Array<userEntityShape | groupEntityShape>;
	wasEdited: boolean;
	restrictionTypeWasChanged: boolean;
	scrollToIndex: number | null;
	isExternalShareVariation: boolean;
	isPageExternallyShared: boolean;
	isExternalShareEnabledForSite: boolean;
	isContentExternallySharable: boolean;
	canEditPublicLink: boolean;
	externalCollaboratorCount: number;
	ancestors: (RestrictionsDialogQuery_content_nodes_ancestors | null)[];
	showConfirmDiscardModal: boolean;
	shouldNotify: boolean;
	shouldAllowCopy: boolean;
	isShareInRestrictionsEnabled: boolean;
	hasModifiedGroupRestrictions?: boolean;
	showConfirmGroupModal: boolean;
	showConfirmBulkUsersModal: boolean;
};

export enum OpenDialogType {
	INSPECT_PERMISSIONS = 'INSPECT_PERMISSIONS',
	ACCESS_DETAILS = 'ACCESS_DETAILS',
	RESTRICTIONS = 'RESTRICTIONS',
	PUBLIC_LINKS_VALIDATION = 'PUBLIC_LINKS_VALIDATION',
}

export type RestrictionsDialogDataLoaderProps = {
	restrictionsLoadUuid: string;
	contentId: string;
	flags?: GenericObject;
	restrictionsDataContainer: GenericObject;
	spaceKey: string;
};

export interface RestrictionsDialogFooterProps {
	contentId: string;
	spaceKey: string;
	actions: any[];
	contentType?: string;
	hideShareCTA: boolean;
	isExternalShare: boolean;
	externalCollaboratorCount: number;
	canEditPublicLink: boolean;
	hasViewRestrictions: boolean;
	closeDialog: (callback?: () => void) => void;
	hasSubmitOverride: boolean;
	selectedRestrictionType: ContentRestrictionType;
	isShareInRestrictionsEnabled: boolean;
	newEntities: EntityShape[];
	shouldNotify: boolean;
	shouldAllowCopy: boolean;
	toggleShouldNotify: () => void;
	contentStatus: string | null | undefined;
}

export interface ExternalFooterSectionProps {
	contentId: string;
	hasViewRestrictions: boolean;
	externalCollaboratorCount: number;
	hasPublicLink: boolean;
	canEditPublicLink: boolean;
	spaceKey: string;
}

export interface NoRestrictionsProps {
	contentType?: string;
	isExternalShareVariation: boolean;
	isPageExternallyShared: boolean;
	isExternalShareEnabledForSite: boolean;
	hideShareCTA: boolean;
	closeDialog: (callback?: () => void) => void;
}

export interface RestrictionsDialogHeaderProps {
	contentType?: string;
	titleRef?: RefObject<HTMLHeadingElement>;
	onInspectPermissionsClick: GenericFunction;
	onUpFlowOpen: GenericFunction;
	isRestrictionsContentLoaded: boolean;
	isShareInRestrictionsEnabled: boolean;
}

type PrincipalsCountType = {
	userCount: number;
	groupCount: number;
};

export interface UserAndGroupSelectFormProps {
	currentPrincipalsCount: PrincipalsCountType;
	mode: UserAndGroupSelectFormComponentMode;
	onAdd: GenericFunction;
	menuPortalTarget?: HTMLElement | null;
	styles?: StylesConfig;
}

export interface IsUpsellQueryComponentProps extends ComponentProps<any> {
	contentId?: string;
}
